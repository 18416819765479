<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input clearable label="求购标题" v-model="searchParam.topicTitle" />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" @click="check(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      :title="title"
      v-model="show"
      @confirm="confirm"
      sureTxt="确定"
      :showCancelButton="false"
      oStyle="justify-content: flex-end;"
      @close="closeDialog"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="求购标题">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.topicTitle"
            disabled
          ></v-input> </el-form-item
        ><el-form-item label="发布时间">
          <v-input
            clearable
            class="filter-item"
            v-model="temp.createTime"
            disabled
          ></v-input>
        </el-form-item>
        <el-form-item label="发布图片">
          <v-upload :limit="9" disabled :imgUrls="temp.topicInteriorView" />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";
import { purchaseListUrl, purchaseDeleteUrl } from "./api.js";

export default {
  name: "purchasingManagement",
  data() {
    return {
      proposeAnnexFiles: "",
      title: "查看",
      show: false,
      searchParam: {
        topicTitle: null,
        topicType: 42,
      },
      temp: {},
      tableUrl: purchaseListUrl,
      headers: [
        {
          prop: "topicTitle",
          label: "求购标题",
        },
        {
          prop: "commentCount",
          label: "评论数量",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: row.applyUserCount,
              cb: () => this.toPath(row),
            }),
        },
        {
          prop: "createTime",
          label: "发布时间",
        },
      ],
    };
  },
  methods: {
    toPath(data) {
      console.log(data);
      this.$router.push({
        name: "purchasingCommentsManagement",
        query: {
          id: data.id,
          topicTitle: data.topicTitle,
        },
      });
    },
    closeDialog() {
      console.log(123);
    },
    confirm() {
      this.show = false;
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${purchaseDeleteUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    check(item) {
      this.title = "查看";
      this.show = true;
      this.temp = item;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
